<template>
  <div>
    <a-modal
      :visible.sync="show"
      width="900px"
      :footer="null"
      maskClosable
      @cancel="$emit('update:show', false)"
    >
      <div class="flex justify-between">
        <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
          <a-tab-pane v-if="hasDelivery" key="delivery" tab="配送地图"></a-tab-pane>
          <template v-if="hasExpress">
            <a-tab-pane
              v-for="(item, index) in expressList"
              :key="index"
              :tab="item.name"></a-tab-pane>
          </template>
        </a-tabs>
      </div>
      <!-- 配送地图 -->
      <div v-if="activeKey=='delivery'" class="content">
        <div id="queryMap" class="map"></div>
        <div class="info">
          <h4>配送员: {{courierInfo.courier_name}}</h4>
          <h4>联系电话: {{courierInfo.courier_phone}}</h4>
        </div>
      </div>
      <!-- 快递 -->
      <div v-else-if="activeExpress" class="content">
        <ul class="content-express">
          <li v-for="(speed, index) in activeExpress.speed" :key="index">{{speed.title}}：{{speed.content}}</li>
        </ul>
        <div class="info">
          <h4>{{activeExpress.express_name}}运单号: {{activeExpress.waybill_no}}</h4>
        </div>
      </div>

    </a-modal>
  </div>
</template>

<script>
import { getCourierInfo, getExpressInfo } from "@/api/customer.js"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    requestID: {
      type: String,
    },
    hasDelivery: {
      type: Boolean,
      default: false,
    },
    hasExpress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeKey: '',
      courierInfo: {},
      consigneeArr: [],
      shopStyle: {
        shopMarker: new window.TMap.MarkerStyle({
          width: 43, // 点标记样式宽度（像素）
          height: 49, // 点标记样式高度（像素）
          src: require("@/assets/icon-shop.png"), //图片路径
          anchor: { x: 21.5, y: 49 },
        }),
        peisongMarker: new window.TMap.MarkerStyle({
          width: 36, // 点标记样式宽度（像素）
          height: 47, // 点标记样式高度（像素）
          src: require("@/assets/icon-peisong.png"), //图片路径
          anchor: { x: 18, y: 46 },
        }),
        userMarker: new window.TMap.MarkerStyle({
          width: 26, // 点标记样式宽度（像素）
          height: 36, // 点标记样式高度（像素）
          src: require("@/assets/icon-user.png"), //图片路径
          anchor: { x: 13, y: 35 },
        }),
      },
      markerList: [
        {
          id: "1",
          styleId: "shopMarker",
          outlets_name: "网点",
        },
        {
          id: "2",
          styleId: "peisongMarker",
          outlets_name: "配送员",
        },
        {
          id: "3",
          styleId: "userMarker",
          outlets_name: "用户",
        },
      ],
      expressList: [],
      activeExpress: null,
    }
  },
  mounted() {
    if(this.hasDelivery){
      this.activeKey = "delivery"
    }else{
      this.activeKey = 0
    }

    if(this.hasDelivery){
      this.initDelivery()
    }
    if(this.hasExpress){
      this.initExpress()
    }
  },
  methods: {
    handlerTypeChange(v){
      this.activeKey = v;
      this.render();
    },

    render(){
      if(this.activeKey == "delivery"){
        this.activeExpress = null
        if(this.consigneeArr && this.consigneeArr.length){
          this.initMap(Number(this.consigneeArr[1]), Number(this.consigneeArr[0]))
        }
      }else{
        let index = parseInt(this.activeKey) || 0
        this.activeExpress = this.expressList[index]
      }
    },

    async initExpress() {
      const { code, data } = await getExpressInfo({ order_no: this.requestID })
      if (code == 0) {
        let list = data.waybill_list || []

        if(!list.length){
          list.push({
            "express_name": "",
            "waybill_no": "暂无",
            "speed": [{
                'title': "暂无物流信息",
                'content': "如有需要、可以检查发货进度"
              }
            ]
          })
        }
        this.expressList = list.map((item, index)=>{
          item.name = list.length>1 ? "快递包裹"+(index+1) : "快递包裹"
          return item
        })

        if(this.activeKey != "delivery"){
          this.render();
        }
      }
    },

    async initDelivery() {
      const { code, data } = await getCourierInfo({ order_no: this.requestID })
      if (code == 0) {
        this.courierInfo = data

        // 网点
        let outletsArr = []
        if (this.courierInfo.outlets_lng_lat.length > 0) {
          outletsArr = this.courierInfo.outlets_lng_lat.split(" ")
        } else {
          // test 干嘛的
          // outletsArr = [116.477694, 39.917234]
        }

        // 配送员
        let courierArr = []
        if (this.courierInfo.courier_lng_lat.length > 0) {
          courierArr = this.courierInfo.courier_lng_lat.split(" ")
          if(courierArr[0] == "0") courierArr = []
        }
        // 配送员
        if (courierArr.length > 0) {
          // courierArr = this.courierInfo.courier_lng_lat.split(" ")
          this.markerList = [
            {
              id: "1",
              styleId: "shopMarker",
              outlets_name: "网点",
            },
            {
              id: "2",
              styleId: "peisongMarker",
              outlets_name: "配送员",
            },
            {
              id: "3",
              styleId: "userMarker",
              outlets_name: "用户",
            },
          ]
        } else {
          courierArr = []
          this.markerList = [
            {
              id: "1",
              styleId: "shopMarker",
              outlets_name: "网点",
            },
            {
              id: "3",
              styleId: "userMarker",
              outlets_name: "用户",
            },
          ]
        }

        // 收件人
        let consigneeArr = []
        if (this.courierInfo.consignee_lng_lat.length > 0) {
          consigneeArr = this.courierInfo.consignee_lng_lat.split(" ")
        } else {
          // test 干嘛用的
          // consigneeArr = [116.477694, 39.917234]
        }

        // 网点
        this.markerList[0].position = new window.TMap.LatLng(
          Number(outletsArr[1]),
          Number(outletsArr[0])
        )

        // 配送员
        if (courierArr.length > 0) {
          this.markerList[1].position = new window.TMap.LatLng(
            Number(courierArr[1]),
            Number(courierArr[0])
          )
        }

        // 收件人
        if (courierArr.length > 0) {
          this.markerList[2].position = new window.TMap.LatLng(
            Number(consigneeArr[1]),
            Number(consigneeArr[0])
          )
        } else {
          this.markerList[1].position = new window.TMap.LatLng(
            Number(consigneeArr[1]),
            Number(consigneeArr[0])
          )
        }

        this.consigneeArr = consigneeArr
        // this.initMap(Number(consigneeArr[1]), Number(consigneeArr[0]))

        if(this.activeKey == "delivery"){
          this.render();
        }
      }
    },

    initMap(la, lo) {
      setTimeout(() => {
        // 设置中心点坐标
        let center = new window.TMap.LatLng(la, lo)
        // 初始化地图
        let map = new window.TMap.Map(document.getElementById("queryMap"), {
          center: center,
          zoom: 15,
        })
        new window.TMap.MultiMarker({
          id: "shop_map_container_layer",
          map: map,
          styles: this.shopStyle,
          geometries: this.markerList,
        })
        let bounds = new window.TMap.LatLngBounds()
        //设置自适应显示marker
        //判断标注点是否在范围内
        this.markerList.forEach(function (item) {
          //若坐标点不在范围内，扩大bounds范围
          if (bounds.isEmpty() || !bounds.contains(item.position)) {
            bounds.extend(item.position)
          }
        })
        //设置地图可视范围
        map.fitBounds(bounds, {
          padding: 100, // 自适应边距
        })
      }, 0)
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  // padding: 15px 10px 0;
  .map{
    width: 100%;
    height: 500px;
    background-color: white;
  }
  .content-express{
    width: 100%;
    height: 500px;
    overflow-y: auto;
    margin: 0;
    padding: 10px 0 0 20px;
  }
  .info {
    margin: 10px 0 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>