<template>
  <div>
    <div class="search-bar">
      <a-form-model :labelCol="{ span: 8 }"
                    :wrapperCol="{ span: 16 }"
                    ref="searchRef"
                    :model="searchForm">
        <div class="flex justify-between items-center">
          <a-form-model-item class="mb-0" prop="search_order_no" label="订单ID">
            <a-input style="width:100%;" placeholder="请输入订单ID"
                     allowClear
                     v-model="search_order_no"
                     @keyup.enter="handlerSearch"></a-input>
          </a-form-model-item>
          <a-form-model-item class="mb-0 ml-2" prop="order_add_time_start"
                             label="下单日期开始">
            <a-date-picker style="width:100%;" placeholder="选择日期" 
                      value-format="YYYY-MM-DD" v-model="searchForm.order_add_time_start" />
          </a-form-model-item>
          <a-form-model-item class="mb-0 ml-2" prop="order_add_time_end"
                             label="下单日期截止">
            <a-date-picker style="width:100%;" placeholder="选择日期" 
                      value-format="YYYY-MM-DD" v-model="searchForm.order_add_time_end" />
          </a-form-model-item>
          <div class="flex ml-4">
            <a-button class="p-0" type="link" @click="resetForm">重置</a-button>
            <a-button class="ml-4" type="primary" @click="handlerSearch">搜索</a-button>
          </div>
        </div>
      </a-form-model>
    </div>

    <!-- 全部订单列表 -->
    <base-table
        rowKey="suborder_no"
        :columnsData="newColumns"
        :tableData="tableData"
        :total="total"
        :getListFunc="initData"
        :page.sync="searchForm.page"
        :pageSize.sync="searchForm.page_count">
      <template #suborder_status="{ record }">
        <a-tag class="mr-0" :color="getUserStatusTagClass(record.suborder_status)"
            >{{ formatExpressOrderStatus(record.suborder_status) }}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button type="link" class="p-0" @click="handlerTryFinish(record)">刷新完成状态</a-button>
        <a-button type="link" class="p-0 ml-4" @click="handlerQuery(record)">物流</a-button>
        <a-button type="link" class="p-0 ml-4" @click="handlerDetail(record)">详情</a-button>
      </template>
    </base-table>

    <!-- 物流 -->
    <QueryWindow v-if="isShowQuery" :show.sync="isShowQuery" 
        :requestID="activeRow.order_no" 
        hasExpress />
    <!-- 强制完成 -->
    <ForceFinishExpress v-if="isShowForceFinish" :show.sync="isShowForceFinish"
        :orderData="activeRow"
        @ok="handlerForceFinish"/>

  </div>
</template>

<script>
import {
  expressPendingList,
  tryFinishExpressOrder,
} from "@/api/customer.js"
import QueryWindow from "@/components/order/queryWindow"
import ForceFinishExpress from "./components/force-finish-express"

export default {
  components: {
    QueryWindow,
    ForceFinishExpress
  },
  data () {
    return {
      total: 0,
      tableData: [],

      search_order_no: "",
      searchForm: {
        order_no: "",
        suborder_no: "",
        // order_add_time_arr: [],
        order_add_time_start: "",
        order_add_time_end: "",
        page: 1,
        page_count: 20,
      },

      newColumns: [
        {
          title: "订单号",
          dataIndex: "order_no",
          align: "center",
          width: 180,
        },
        {
          title: "子订单号",
          dataIndex: "suborder_no",
          align: "center",
          width: 150,
        },
        {
          title: "下单时间",
          dataIndex: "order_add_time",
          align: "center",
          width: 200,
        },
        {
          title: "子订单状态",
          dataIndex: "suborder_status",
          align: "center",
          width: 100,
          slots: {
            customRender: "suborder_status",
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          width: 210,
          slots: { customRender: "operation" },
        },
      ],

      activeRow: null,

      isShowQuery: false,
      isShowForceFinish: false,

    }
  },

  async mounted () {
    this.handlerSearch()
  },
  methods: {
    handlerSearch () {
      this.searchForm.order_no = ""
      this.searchForm.suborder_no = ""
      let keyword = (this.search_order_no || "").replace(/^\s*/g, "").replace(/\s*$/g, "")
      if(keyword){
        if(/^\d{16}$/.test(keyword)){
          this.searchForm.order_no = keyword
        }else if(/^s\d{12}$/i.test(keyword)){
          this.searchForm.suborder_no = keyword
        }else{
          this.$message.info("请输入有效订单号")
          return
        }
      }

      this.searchForm.page = 1
      this.initData()
    },
    resetForm () {
      this.search_order_no = ""
      this.searchForm.suborder_no = ""
      this.$refs.searchRef.resetFields()

      this.handlerSearch()
    },

    async initData () {
      const params = {
        ...this.searchForm,
      }
      const { data, code } = await expressPendingList(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },

    async handlerTryFinish(row){
      this.activeRow = row
      const suborder_no = row.suborder_no
      const { code } = await tryFinishExpressOrder({
        suborder_no: suborder_no,
        verify_wdt: 2, // 验证旺店通(1-否，2-是)
      })
      if (code == 0) {
        const _this = this
        this.$confirm({
          title: '刷新成功',
          okText: "查看订单",
          cancelText: "取消",
          onOk () {
            _this.handlerDetail(_this.activeRow)
          },
        });
        this.initData()
      }else{
        setTimeout(()=>{
          this.isShowForceFinish = true
        }, 500)
      }
    },

    async handlerForceFinish(suborder_no){
      const { code } = await tryFinishExpressOrder({
        suborder_no: suborder_no,
        verify_wdt: 1, // 验证旺店通(1-否，2-是)
      })
      if (code == 0) {
        const _this = this
        this.$confirm({
          title: '已强制完成快递子订单',
          okText: "查看订单",
          cancelText: "取消",
          onOk () {
            _this.handlerDetail(_this.activeRow)
          },
        });
        this.initData()
      }
    },

    handlerQuery(row){
      this.activeRow = row
      this.isShowQuery = true
    },
    //详情
    handlerDetail (row) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: row.order_no },
      });
      window.open(newPage.href, "_blank");
    },
    
    formatExpressOrderStatus(status){
      // 1-未审核，2-已审核，3-备货中，4-配送中
      if(status == 1) return "未审核"
      if(status == 2) return "已审核"
      if(status == 3) return "备货中"
      if(status == 4) return "配送中"
      return ""
    },
    
    getUserStatusTagClass(status){
      // 1-未审核，2-已审核，3-备货中，4-配送中
      if(status < 2) return "grey"
      if(status < 4) return "blue"
      if(status == 4) return "green"
      return ""
    },

    // setRowClass (record) {
    //   const time = new Date().getTime() - new Date(record.order_add_time.replace(/-/g,"/")).getTime()
    //   // 超30天
    //   if(time > 3600*1000*24*30){
    //     return "row-mine"
    //   }
    //   return ""
    // },

  },
}
</script>

<style lang="less">
.grey{
  color: #999;
}
</style>
