<template>
  <a-modal
    :visible.sync="show"
    title="刷新失败。您可以强制完成快递子订单："
    destroyOnClose
    width="550px"
    @cancel="handlerCancel"
    :footer="null"
  >
  <div class="justify-center">
    <div style="width:100%;">
      <div>
        
      </div>
      
      <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
        label="订单号" style="margin-bottom:0;">
        {{orderData.order_no}}
      </a-form-model-item>
      <a-form-model-item :labelCol="{span:5}" :wrapperCol="{span:19}" 
        label="子订单号" style="margin-bottom:10px;">
        {{orderData.suborder_no}}
      </a-form-model-item>

      <div style="text-align:center; color:#999;">
        * 如果旺店通未使用专用账号发货、就会出现这种系统无法自动完成的订单
      </div>

      <a-divider />

      <div style="text-align:center;">
        <div style="color:#f00;">请务必到旺店通检查该订单的所有快递单均已送到或者已取消、再点强制完成</div>
      </div>
      <div class="mt-5 mb-5 flex justify-center items-center">
        <a-checkbox class="mr-4" v-model="isChecked">已检查 均是已送达/已取消</a-checkbox>
        <a-button type="primary" @click="handlerOk">强制完成快递子订单</a-button>
      </div>
    </div>
  </div>
    
  </a-modal>
</template>

<script>

export default {
  components: {  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orderData: {
      tyep: Object,
      default: null
    }
  },
  data() {
    return {
      isChecked: false
    }
  },
  async mounted() {

  },
  methods: {

    handlerOk(){
      if(this.isChecked){
        this.$emit('ok', this.orderData.suborder_no)
      }else{
        this.$message.info("请先检查旺店通订单")
      }
    },

    handlerCancel(){
      this.$emit('update:show', false)
    }
  }
}
</script>

<style>
</style>